//
//** Line Tabs
//

//== Component Base
@mixin m-build-component--tabs-line-base() {
    .m-tabs-line {
        margin: 0 0 25px 0;
        @include clearfix();

        .m-tabs__item {
            margin-right: 30px;
            margin-bottom: -1px;

            &:last-child {
                margin-right: 0;
            }
        }

        .m-tabs__link, .m-tabs__button, 
        .m-tabs__tab{
            border: 0;
            border-bottom: 1px solid transparent;
            padding: 12px 0;
            font-size: get-font-size(regular, '+');
            font-weight: get-font-weight(bold);

            @include icons-attr(font-size, (fontawesome: 1.4rem, lineawesome: 1.6rem, metronic: 1.6rem) );  
            i {
                vertical-align: middle;
                line-height: 0;
                display: inline-block;  
                margin-right: 0.5rem;
            }     
        }

        &.m-tabs-line--2x {
            a.m-tabs__link {
                &:hover,
                &.active {
                    border-bottom-width: 4px !important;
                }
            }
        }

        &.m-tabs-line--left {
            float: left;
        }

        &.m-tabs-line--right {
            float: right;
        }
    }
}

//== Component Skin
@mixin m-build-component--tabs-line-skin($skin) {
    @include component-skin(m-tabs-line, $skin) {
        border-bottom: 1px solid get-color($skin, panel);

        &.nav.nav-tabs .nav-link,
        a.m-tabs__link {
            background: transparent;
            color: get-color($skin, regular, '--'); 
            
            i {
                color: get-color($skin, regular, '---');   
            }

            &:disabled {
                color: get-color($skin, muted);
            
                i {
                    color: get-color($skin, muted);   
                }
            }

            &:hover,
            &.active {
                background: transparent;
                color: get-color($skin, regular, '+');
                border-bottom: 1px solid get-color($skin, regular, '+');

                > i {
                    color: get-color($skin, regular, '--');
                }
            }
        }

        button.m-tabs__button{
            background: transparent;
            color: get-color($skin, link);

            @include transition(color 0.3s ease-in-out);

            i {
                color: get-color($skin, link);

                @include transition(color 0.3s ease-in-out);
            }

            &:hover {
                color: get-color($skin, link, '+');
                i{
                    color: getcolor($skin, link, '+');
                }
            }
        }

        &.nav.nav-tabs .m-tabs__tab {
            background: transparent;
            color: get-color($skin, regular);
            @include transition(border-bottom 0.3s ease-in-out);
        }

        // state colors
        @each $name, $color in $m--state-colors {
            &.m-tabs-line--#{$name} {      
                &.nav.nav-tabs .nav-link,
                a.m-tabs__link {
                    &:hover {
                        border-bottom: 1px solid array-get($color, base);
                    }

                    &:hover,
                    &.active {
                        color: array-get($color, base);
                        border-bottom: 1px solid array-get($color, base);

                        > i {
                            color: lighten(array-get($color, base), 5%);
                        }
                    }
                }
                &.nav.nav-tabs .m-tabs__tab {
                    &:hover,
                    &.active {
                        color: array-get($color, regular);
                        border-bottom: 3px solid array-get($color, base);
                        border-radius: 3px;
                    }
                } 
            }
            .m-tabs-line-color--#{$name} {
                .m-tabs__tab {
                    &:hover,
                    &.active {
                        color: array-get($color, regular);
                        border-bottom: 3px solid array-get($color, base);
                        border-radius: 3px;
                    }
                }
            }
        }
    }     
}

//== Build Component Base
@include m-build-component--tabs-line-base();

//== Build Component - Light Skin
@include m-build-component--tabs-line-skin(light);

//== Build Component - Dark Skin
@include m-build-component--tabs-line-skin(dark);  